<template>
  <div class="prop"
       v-if="property">
    <div class="locate"
         v-if="property.hightlight">
      {{ property.hightlight }}
    </div>
    <div class="slidecont">
      <a class="like"><i :class="[isFavoriteComputed ? 'fas' : 'far', 'fa-heart']"
                         @click="toggleFavorites(property)" /></a>
      <flickity class="thumbs"
                ref="flickity"
                :options="flickityOptions">
        <div v-for="(image, index) in property.images"
             :key="index"
             class="img">
          <img :data-flickity-lazyload="image.thumbnail">
        </div>
      </flickity>
    </div>
    <a class="info"
       href="javascript:;"
       @click="handleHREF(property)">
      <h5>{{ property.name }} <!-- <small v-if="property.state && property.country">{{ property.state.name }}, {{ property.country.name }}</small> --></h5>
      <div class="features">
        <span><i class="far fa-user" /> {{ property.maximum_capacity }} Travelers</span>
        <span v-if="property.rooms && property.bedrooms > 0"><i class="icon-bed" /> {{ property.bedrooms }} Bedroom{{ property.bedrooms > 1 ? 's' : '' }}</span>
        <span><i class="icon-bath" /> {{ property.number_bathroom }} Bathroom{{ property.number_bathroom > 1 ? 's' : '' }}</span>
      </div>
      <div class="foot">
        <p class="price">
          from <strong>${{ (property.live_price ? property.live_price : property.price)| numFormat() }}</strong>/night {{ hasDates ? 'for the selected dates' : '' }}
        </p>
        <!-- <span class="instant"> {{ property.instant_book ? 'Book now' : 'Request Book' }}</span> -->
      </div>
    </a>
  </div>
</template>
<script>
/* eslint no-param-reassign: "error" */
// eslint-disable-next-line
import Flickity from 'vue-flickity';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'PropertyListResult',
  props: {
    property: {
      type: Object,
      default: null
    },
    hasDates: {
      type: Boolean,
      default: false
    },
  },
  components: {
    Flickity,
  },
  data() {
    return {
      properties: {},
      isFavorite: false,
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
        lazyLoad: 2,
      },
    };
  },
  methods: {
    ...mapActions([
      'deleteFavoritesProperties',
      'updateFavoritesProperties',
      'updateFavoritesPropertiesLocalStorage',
    ]),
    ...mapGetters([
      'getUser',
    ]),
    toggleFavorites(property) {
      if (this.getUser()) {
        this.updateFavoritesProperties(property.id);
      } else {
        this.updateFavoritesPropertiesLocalStorage(property);
      }
      this.isFavorite = !this.isFavorite;
    },
    handleHREF(property) {
      const { href } = this.$router.resolve({
        name: 'property',
        params: { id: property.id },
        query: this.$route.query
      });
      window.open(href, '_blank');
    },
  },
  computed: {
    isFavoriteComputed() {
      return this.isFavorite;
    },
  },
  created() {
    this.isFavorite = this.property.isFavorite;
  },
};
</script>

<style scoped>

</style>
