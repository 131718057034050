<template>
  <div>
    <MyHeader />
    <main id="content"
          v-if="!isLoading">
      <nav class="search-filters-nav">
        <div class="group d-none d-lg-flex">
          <HotelDatePicker
            :starting-date-value="config.check_in"
            :ending-date-value="config.check_out"
            :class="config.check_out && config.check_in && 'active' || temporal_check_in && temporal_check_out && 'active'"
            @check-in-changed="setCheckinDate"
            @check-out-changed="setCheckoutDate" />
        </div>
        <div class="group d-none d-lg-flex col-lg-auto">
          <i class="far fa-user" />
          <v-select class="guestSelect"
                    v-model="config.travelers"
                    :options="travelerOptions"
                    placeholder="Travelers"
                    @input="updateProperties" />
        </div>
        <div class="group d-none d-lg-flex">
          <i class="icon-price" />
          <vue-slider style="width: 250px"
                      v-model="config.range_price"
                      :tooltip="'active'"
                      :tooltip-placement="'bottom'"
                      :lazy="true"
                      :min="1"
                      :max="10000"
                      @change="priceChange"
                      :tooltip-formatter="formatter2" />
        </div>
        <!-- <div class="group d-lg-flex">
          <button onClick="$('.dropRange-content').toggle();">
            <i class="icon-price" />
            <span id="lower-value"
                  class="mr-1">$600</span> to
            <span id="upper-value"
                  class="ml-1" />
          </button>
          <div class="dropRange-content">
            <div id="priceRange" />
          </div>
        </div> -->
        <!-- <div class="group">
          <button href="#!">
            <i class="icon-equalizer" /> More Filters
          </button>
        </div> -->
      </nav>
      <div class="results-layout">
        <div class="p-3 pt-lg-3 pb-lg-5 pl-lg-5 pr-lg-5 properties">
          <h4 class="result-title"
              v-if="getProperties && getProperties.length > 0">
            {{ getProperties.length }} Exclusive properties in <strong>Cap Cana, Dominican Republic</strong>
          </h4>
          <hr>
          <div class="result-list"
               v-if="getProperties && getProperties.length > 0">
            <PropertyListResult v-for="property in getProperties"
                                :key="property.id"
                                :property="property"
                                :has-dates="config.check_in && config.check_out ? true : false" />
          </div>
          <div v-else>
            <p style="font-size: 26px;"
               v-if="!this.$route.query.category_id">
              Oops, we do not have properties available for your selection, please try changing your dates or travelers
            </p>
            <p style="font-size: 26px;"
               v-else>
              Oops, we do not have properties available for your selection, please try changing the category
            </p>
          </div>
          <div class="pagination-container">
            <ul class="pagination mb-1"
                v-if="getProperties && getProperties.length > 0 && computedPagination && computedPagination.has_page">
              <li :class="{ 'page-item': true, active: computedPagination.current_page == index + 1 }"
                  v-for="(page, index) in computedPagination.total"
                  :key="index + 1">
                <a class="page-link"
                   href="javascript:;"
                   @click="changePage(index + 1)">{{ index + 1 }}</a>
              </li>
            </ul>
            <p v-if="computedPagination && computedPagination.from && computedPagination.to">
              {{ computedPagination.from }} – {{ computedPagination.to }} of {{ computedPagination.count }} Properties
            </p>
          </div>
        </div>
        <div class="mapcanvas">
          <GmapMap :center="{ lat: center && center[0] ? center[0] : 18.6406656, lng: center && center[1] ? center[1] : -68.611432 }"
                   :zoom="config.zoom"
                   ref="mapRef"
                   @zoom_changed="onMapChange($event, 'zoom_changed')"
                   @center_changed="onMapChange($event, 'center_changed')"
                   @dragend="onMapChange($event, 'dragend')"
                   class="embed-responsive-item mapWH"
                   style="min-height: 600px!important;">
            <gmap-info-window
              v-if="getProperties && getProperties.length > 0"
              :position="infoWindowPos"
              :opened="infoWinOpen"
              @closeclick="infoWinOpen=false">
              <a class="info"
                 @click="handleHREF(tempProperty)">
                <div class="">
                  <ul class="prop-list"
                      v-if="tempProperty">
                    <li>
                      <div class="item">
                        <div class="locate"
                             v-if="tempProperty.hightlight">
                          {{ tempProperty.hightlight }}
                        </div>
                        <!-- <flickity v-if="isInfoWindowsOpen"
                                  class="thumbs"
                                  ref="flickity"
                                  :options="flickityOptions">
                          <div v-for="(image, _index) in tempProperty.images"
                                :key="_index"
                                class="img">
                            <img :data-flickity-lazyload-src="image.thumbnail">
                          </div>
                        </flickity> -->
                        <img
                          :src="tempProperty.images[0].thumbnail"
                          class="thumbs"
                          style="z-index: 0;"
                          v-if="tempProperty && tempProperty.images">
                        <div
                          class="info-mask"
                          style="z-index: 1;">
                          <a class="info"
                             @click="handleHREF(tempProperty)">
                            <h5 class="text-white">{{ tempProperty.name }} <!-- <small v-if="tempProperty.state && tempProperty.country">{{ tempProperty.state.name }}, {{ tempProperty.country.name }}</small>--></h5>
                            <!-- <div class="features">
                              <span><i class="icon-users" />{{ tempProperty.maximum_capacity }}</span>
                              <span v-if="tempProperty.beds && tempProperty.beds.length > 0"><i class="icon-bed" />{{ tempProperty.beds.length }}</span>
                              <span><i class="icon-bath" />{{ tempProperty.number_bathroom }}</span>
                            </div> -->
                            <p class="price text-white">
                              from<strong class="text-white">${{ tempProperty.price }}</strong>/night
                            </p>
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </a>
            </gmap-info-window>
            <div v-if="getProperties && getProperties.length > 0">
              <div v-for="(property) in properties"
                   :key="property.id">
                <gmap-custom-marker :marker="{ lat: property.lat, lng: property.lng }"
                                    @click.native="toggleInfoWindow(property, property.id)">
                  <div style="width: 50px;  border: 1px solid white; border-radius: 25px; height: auto; background-color: #11568C; color: white; padding: 8px; text-align: center; font-size: 12px; font-weight: bold;">
                    from ${{ (property.live_price ? property.live_price : property.price)| numFormat() }}
                  </div>
                </gmap-custom-marker>
              </div>
            </div>
          </GmapMap>
        </div>
      </div>
      <newsletter />
    </main>
    <MyFooter />
  </div>
</template>

<script>
import { gmapApi } from 'vue2-google-maps';
import GmapCustomMarker from 'vue2-gmap-custom-marker';
import PropertyListResult from '@/components/PropertyListResult.vue';
import vSelect from 'vue-select';
import HotelDatePicker from 'vue-hotel-datepicker';
import { mapActions, mapGetters } from 'vuex';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css';
import Newsletter from '../components/Newsletter.vue';
// import Flickity from 'vue-flickity';

export default {
  name: 'Property',
  components: {
    vSelect,
    PropertyListResult,
    HotelDatePicker,
    VueSlider,
    Newsletter,
    // Flickity,
    'gmap-custom-marker': GmapCustomMarker,
  },
  data() {
    return {
      pagination: null,
      properties: null,
      favoritesProperties: null,
      center: null,
      temporal_check_in: null,
      temporal_check_out: null,
      config: {
        limit: 10,
        page: 1,
        sort_field: null,
        sort_order: null,
        params: null,
        travelers: {},
        destination: {
          id: 534,
          label: 'Punta Cana, Dominican Republic',
          type: 'state'

        },
        category_id: null,
        range_price: [1, 10000],
        check_in: null,
        check_out: null,
        lat: '',
        lng: '',
        zoom: 10,
      },
      dragEnd: false,
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        content: '',
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      temporalProperty: {},
      formatter2: v => `$${(`${v}`).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
      destinationOptions: [],
      travelerOptions: [
        { label: '1+ Travelers', value: '1' },
        { label: '2+ Travelers', value: '2' },
        { label: '3+ Travelers', value: '3' },
        { label: '4+ Travelers', value: '4' },
        { label: '5+ Travelers', value: '5' },
        { label: '6+ Travelers', value: '6' },
        { label: '7+ Travelers', value: '7' },
        { label: '8+ Travelers', value: '8' },
        { label: '9+ Travelers', value: '9' },
        { label: '10+ Travelers', value: '10' },
      ],
      isInfoWindowsOpen: false,
    };
  },
  metaInfo: {
    title: 'Results'
  },
  computed: {
    google: gmapApi,
    getProperties() {
      return this.properties;
    },
    tempProperty() {
      return this.temporalProperty;
    },
    computedPagination() {
      return this.pagination;
    },
    isLoading() {
      return this.$store.getters.getIsLoading;
    },
  },
  methods: {
    ...mapActions([
      'requestPlaces',
      'requestProperties',
      'requestFavoritesProperties',
    ]),
    ...mapGetters([
      'getUser',
      'getFavoritesPropertiesLocalStorage',
    ]),
    handleHREF(property) {
      const { href } = this.$router.resolve({
        name: 'property',
        params: { id: property.id },
        query: this.$route.query
      });
      window.open(href, '_blank');
    },
    onMapChange(event, flag) {
      this.dragEnd = flag === 'dragend';
      if (flag !== 'zoom_changed' && event && event.lat() && event.lng()) {
        this.config.lat = event.lat();
        this.config.lng = event.lng();
      }
      if (flag === 'zoom_changed') {
        this.config.zoom = event;
        this.updateProperties();
      }
      if (this.dragEnd) {
        this.config.page = 1;
        this.updateProperties();
        this.dragEnd = false;
      }
    },
    toggleInfoWindow(property, idx) {
      this.infoWindowPos = { lat: parseFloat(property.lat), lng: parseFloat(property.lng) };
      this.temporalProperty = property;
      this.infoOptions.content = property.name;
      this.$nextTick(() => {
        setTimeout(() => { this.isInfoWindowsOpen = true; /* this.$refs.flickity.next(); */ }, 500);
      });
      if (this.currentMidx === idx) {
        // check if its the same property that was selected if yes toggle
        this.infoWinOpen = !this.infoWinOpen;
      } else {
        // if different property set infowindow to open and reset current property index
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    onSearch(search, loading) {
      loading(true);
      // this.requestPlaces({ search: escape(search), destination: this.config.destination }).then((response) => {
      //   this.destinationOptions = response;
      //   loading(false);
      // });
      this.requestPlaces('').then((response) => {
        this.destinationOptions = response;
        loading(false);
      });
    },
    priceChange() {
      this.updateProperties();
    },
    updateQuery() {
      this.$router.push({ name: 'results', query: { check_in: this.config.check_in, check_out: this.config.check_out, travelers: JSON.stringify(this.config.travelers), destination: JSON.stringify(this.config.destination) } });
    },
    setCheckinDate(checkIn) {
      this.$localStorage.set('check_in', checkIn);
      this.config.check_in = checkIn;
    },
    setCheckoutDate(checkOut) {
      this.$localStorage.set('check_out', checkOut);
      this.config.check_out = checkOut;
      this.updateProperties();
    },
    updateProperties(recenter = null) {
      if (recenter) {
        this.config.lat = '';
        this.config.lng = '';
        this.center = null;
        this.config.page = 1;
      }
      // this.updateMapZoom();
      this.requestProperties(this.config).then((propertiesResponse) => {
        const propertiesArray = propertiesResponse.properties;
        this.pagination = propertiesResponse.pagination;
        Promise.all([this.getUser() ? this.requestFavoritesProperties() : this.getFavoritesPropertiesLocalStorage()]).then((response) => {
          const favoriteProperties = response[0] && response[0].data ? response[0].data.data : response[0];
          if (favoriteProperties && favoriteProperties.lenght > 0) {
            propertiesArray.forEach((property, index) => {
              propertiesArray[index].isFavorite = favoriteProperties.some(p => p.id === property.id);
            }, propertiesArray);
          }
          this.properties = propertiesArray;
          this.center = propertiesResponse.center;
        });
        if (propertiesResponse.properties.length <= 0) {
          this.config.page = 1;
        }
      });
    },
    // updateMapZoom(zoom) {
    //   if (this.config.destination && this.config.destination.type === 'country') {
    //     this.config.zoom = 10;
    //   } else if (this.config.destination && this.config.destination.type === 'state') {
    //     this.config.zoom = 12;
    //   } else if (this.config.destination && this.config.destination.type === 'place') {
    //     this.config.zoom = 14;
    //   } else {
    //     this.config.zoom = 10;
    //   }
    //   this.updateProperties();
    // },
    changePage(page) {
      this.config.page = page;
      this.updateProperties();
      setTimeout(() => {
        this.$scrollTo('#content', 500);
      }, 1000);
    },
    checkNotNull(value) {
      return value !== 'null' && value !== null && value !== '';
    }
  },
  created() {
    this.config.category_id = this.$route.query.category_id;
    if (this.config.category_id) {
      this.config.zoom = 5;
    }
    // if (this.$route.query.travelers && this.$route.query.destination) {
    if (this.checkNotNull(this.$localStorage.get('check_in')) && this.checkNotNull(this.$localStorage.get('check_out'))) {
      this.config.check_in = new Date(this.$localStorage.get('check_in'));
      this.config.check_out = new Date(this.$localStorage.get('check_out'));
    }
    if (this.$route.query.travelers) {
      this.config.travelers = JSON.parse(this.$route.query.travelers);
    }
    if (this.$route.query.destination) {
      this.config.destination = JSON.parse(this.$route.query.destination);
    }
    if (this.$route.query.lat && this.$route.query.lng) {
      this.config.lat = this.$route.query.lat;
      this.config.lng = this.$route.query.lng;
    }

    // this.updateMapZoom();
    // this.requestPlaces({ search: '', destination: this.config.destination }).then((response) => {
    //   this.destinationOptions = response;
    // });
    this.requestPlaces('').then((response) => {
      this.destinationOptions = response;
    });
    this.updateProperties();
    // }
    // else {
    //   this.$router.push('/');
    // }
  },
};
</script>

<style lang="scss">
.mapWH {
  width: 100%;
  height: 100%;
}
.vue-slider-process {
  background-color: #11568C!important;
}
.vue-slider-dot-handle {
  border:2px solid #11568C!important;
}
</style>
